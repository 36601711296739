<template>
    <LayoutReconciliationTab 
        pageType="offers"
        :isDescription="true"
    />
</template>

<script>
import LayoutReconciliationTab from '../layouts/LayoutReconciliationTab.vue';
export default {
    name: 'PageReconciliationOffers',
    components: {LayoutReconciliationTab}
}
</script>